import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'subscriptionInfo',
  ]

  connect() {
    Helpers.ajax({
      url: this.subscriptionInfoTarget.dataset.url,
      type: 'GET',
      success: (response, status, xhr) => {
        this.subscriptionInfoTarget.innerHTML = xhr.responseText
      },
    })
  }
}
