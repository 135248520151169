import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'monthlyButton',
    'yearlyButton',

    'monthlyPrice',
    'yearlyPrice',

    'subscribeMonthlyButton',
    'subscribeYearlyButton',
  ]

  connect() {
    this.pricing = 'yearly'
    this.update()
  }

  showYearlyPrices() {
    this.pricing = 'yearly'
    this.update()
  }

  showMonthlyPrices() {
    this.pricing = 'monthly'
    this.update()
  }

  update() {
    this.monthlyButtonTarget.classList.toggle('active', this.pricing == 'monthly')
    this.yearlyButtonTarget.classList.toggle('active', this.pricing == 'yearly')

    this.yearlyPriceTargets.forEach((target) => toggle(target, this.pricing == 'yearly'))
    this.monthlyPriceTargets.forEach((target) => toggle(target, this.pricing == 'monthly'))

    this.subscribeYearlyButtonTargets.forEach((target) => toggle(target, this.pricing == 'yearly'))
    this.subscribeMonthlyButtonTargets.forEach((target) => toggle(target, this.pricing == 'monthly'))
  }
}
