import { Controller } from "@hotwired/stimulus"
import { Helpers, toggle } from 'pistachio'

export default class extends Controller {
  POLL_TIME_MILLISECONDS = 1000

  static targets = [
    'modal',
    'form',
    'downloadLink',
    'progressBar',
  ]

  static values = {
    'exportUrl': String,
  }

  connect() {
  }

  onExportClick() {
    const path = this.element.dataset.path

    toggle(this.downloadLinkTarget, false)
    this.progressBarTarget.style.width = '0%'
    Helpers.emit(this.modalTarget, 'modal:open!')

    Helpers.ajax({
      type: 'GET',
      url: this.exportUrlValue,
      success: (response) => {
        const statusPath = response['status_path']
        this.poll(statusPath)
      },
    })
  }

  poll(statusPath) {
    Helpers.ajax({
      type: 'GET',
      url: statusPath,
      success: (response) => {
        this.progressBarTarget.style.width = `${response['percent_done']}%`
        if (response.done) {
          toggle(this.downloadLinkTarget, true)
          this.downloadLinkTarget.href = response['download_path']
        } else {
          this.pollingTimeout = setTimeout(() => this.poll(statusPath), this.POLL_TIME_MILLISECONDS)
        }
      },
    })
  }
}
